<template>
  <section id="VideoBoards" class="south">
    <ExperiencesCarousel class="desktop" :carouselImages="carouselImages" />
    <ExperiencesMobile class="mobile" />
  </section>
</template>

<script>
import ExperiencesCarousel from "@/components/global/ExperiencesCarousel";
import ExperiencesMobile from "@/components/sections/Experiences/ExperiencesMobile";

export default {
  name: "VideoBoards",
  components: {
    ExperiencesCarousel,
    ExperiencesMobile,
  },
  data() {
    return {
      carouselImages: [
        {
          // image: "Experiences/Branding/south-video-board-2.jpg",
          image: "Experiences/Branding/south1.jpg",
          title: "South Video Board",
          description:
            "The largest of all the interior boards, the South Video Board is a massive 48’H x 253’L.  Ideal for presentations, sponsor recognition, live feeds, and so much more – this board is the WOW factor.",
        },
        {
          image: "Experiences/Branding/south-video-board.jpg",
          title: "South Video Board",
          description:
            "The South Video Board can seamlessly tie into the entertainment and content that is showcased in any event space.",
        },
        {
          image: "Experiences/Branding/south-video-board-3.jpg",
          title: "South Video Board",
          description:
            "The South Video Board can seamlessly tie into the entertainment and content that is showcased in any event space.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#VideoBoards {
  .desktop {
    @media (max-width: $lg) {
      display: none;
    }
  }
  .mobile {
    position: relative;
    @media (min-width: 993px) {
      display: none;
    }
  }
}
</style>